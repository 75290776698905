import React, { ReactElement, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { ExchangeRatesRequest } from '@api/exchangeRates'
import ErrorPage from '@components/ErrorPage'
import Initializer from '@components/Initializer'
import WhatsappButton from '@components/WhatsappButton'
import config from '@config'
import { useAppContext } from '@context/app'
import useCustomStyles from '@hooks/useCustomStyles'
import useSearchParams from '@hooks/useSearchParams'
import analytics from '@lib/analytics'
import date from '@lib/date'
import onetrust from '@lib/onetrust'
import paramsUtils from '@lib/params'
import style from '@lib/style'
import { useAnalyticsSettings } from '@loaders/analyticsSettings'
import { useExchangeRatesLoader } from '@loaders/exchangeRates'
import { usePassengersTypesLoader } from '@loaders/passengerTypes'
import Metadata from '@pages/Index/Metadata'
import { useSettings } from '@queries/settings'
import { useExchangeRates } from '@stores/exchangeRates'
import { useParams } from '@stores/params'
import { usePassengerTypes } from '@stores/passengerTypes'

const IndexPage = (): ReactElement => {
  const [settings, settingsQuery] = useSettings()
  const { cookiesConsent, whatsappButton } = settings
  const [queryParams] = useSearchParams()
  const [params, setParams, paramsMeta] = useParams()
  const [, setExchangeRates] = useExchangeRates()
  const [, setPassengerTypes] = usePassengerTypes()
  const { retailerPartnerNumber, marketingCarrierCode } = useAppContext()
  const [analyticsInitialized, setAnalyticsInitialized] = useState(false)

  const colors = params.mode === 'page' ? settings.colors : { ...settings.colors, ...style.getEmbedBackgroundColor() }

  const analyticsSettings = useAnalyticsSettings({ retailerPartnerNumber: retailerPartnerNumber })

  useEffect(() => {
    if (!settingsQuery.isFetching && analyticsSettings.data && !analyticsInitialized) {
      if (cookiesConsent.enabled && cookiesConsent.provider === 'onetrust') {
        onetrust.insertScripts()
      }

      analytics.init(analyticsSettings.data)
      setAnalyticsInitialized(true)
    }
  }, [settingsQuery.isFetching, analyticsSettings.data, cookiesConsent, analyticsInitialized])

  const { isLoading: passengerLoading } = usePassengersTypesLoader(
    {
      enabled: settings.passengerTypesList.enabled,
      marketingCarrierCode: marketingCarrierCode ?? settings.passengerTypesList.carrier,
    },
    {
      onSuccess: data => {
        const supported = config.retailerDiscounts[retailerPartnerNumber]?.supportedPassengers
        const types = supported ? data.filter(({ code }) => supported.includes(code)) : data

        setPassengerTypes({ types })
      },
    },
  )

  const exchangeRatesParams: ExchangeRatesRequest = {
    date: date.formatDate(new Date()),
    currencies: settingsQuery.isFetching ? null : settings.currency.supported,
  }
  useExchangeRatesLoader(exchangeRatesParams, {
    onSuccess: rates => {
      setExchangeRates({ rates })
    },
  })

  useEffect(() => {
    if (settingsQuery.isFetching) return

    const normalisedParams = paramsUtils.normaliseParams({
      params: queryParams,
      retailerPartnerNumber,
      settings,
    })

    setParams(normalisedParams)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsQuery.isFetching, queryParams])

  useCustomStyles(colors)

  // Temporary solution, we wait for the params update in case we have RPN
  const paramsLoading = retailerPartnerNumber > 0 ? paramsMeta.isInitialState : false
  const initializerProps = {
    locale: params.locale,
    loading: settingsQuery.isFetching || paramsLoading || passengerLoading,
    params: {
      retailerPartnerNumber: retailerPartnerNumber > 0 ? retailerPartnerNumber : undefined,
      marketingCarrierCode: queryParams.marketingCarrierCode,
    },
  }

  return (
    <Initializer {...initializerProps}>
      {!!settingsQuery.error || <Metadata />}
      {!!settingsQuery.error || <Outlet />}
      {!!settingsQuery.error && <ErrorPage error={settingsQuery.error} />}
      {whatsappButton.enabled && whatsappButton.phoneNumber && (
        <WhatsappButton phoneNumber={whatsappButton.phoneNumber} />
      )}
    </Initializer>
  )
}

export default IndexPage
